var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "font-size": "medium" } },
    [
      _c("app-navigation", {
        attrs: {
          title: _vm.$t("triggers.add-edit"),
          backButtonText: _vm.$t("navigation.back"),
          actionButtonDesc: _vm.$t("navigation.save"),
          actionButtonIcon: "fa fa-save"
        },
        on: { action: _vm.saveTrigger }
      }),
      _c(
        "div",
        {
          staticStyle: {
            margin: "20px",
            "background-color": "#fff",
            border: "1px solid #eeeeee",
            "border-radius": "10px",
            padding: "25px"
          }
        },
        [
          _c(
            "div",
            [
              _c("div", { staticStyle: { color: "#545c64" } }, [
                _vm._v(_vm._s(_vm.$t("triggers.name")))
              ]),
              _c("el-input", {
                staticStyle: { width: "180px", "margin-top": "5px" },
                attrs: { size: "mini" },
                model: {
                  value: _vm.trigger.name,
                  callback: function($$v) {
                    _vm.$set(_vm.trigger, "name", $$v)
                  },
                  expression: "trigger.name"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "when-then", on: { click: _vm.handleWhenClick } },
            [
              _c("div", [
                _c("div", [_vm._v(_vm._s(_vm.$t("triggers.when")))]),
                _c("div", { staticStyle: { "margin-top": "5px" } }, [
                  _vm._v(_vm._s(_vm.whenDescription))
                ])
              ]),
              _c("i", { staticClass: "fa fa-chevron-right" })
            ]
          ),
          _c(
            "div",
            { staticClass: "when-then", on: { click: _vm.handleThenClick } },
            [
              _c("div", [
                _c("div", [_vm._v(_vm._s(_vm.$t("triggers.then")))]),
                _c("div", {
                  staticStyle: { "margin-top": "5px" },
                  domProps: { innerHTML: _vm._s(_vm.thenDescription) }
                })
              ]),
              _c("i", { staticClass: "fa fa-chevron-right" })
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }