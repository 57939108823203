<template>
  <div style="font-size: medium">

    <app-navigation :title="$t('triggers.add-edit')" :backButtonText="$t('navigation.back')" :actionButtonDesc="$t('navigation.save')" actionButtonIcon="fa fa-save" @action="saveTrigger"></app-navigation>

    <div style="margin: 20px; background-color: #fff; border: 1px solid #eeeeee; border-radius: 10px; padding: 25px">

      <div>
        <div style="color: #545c64">{{$t('triggers.name')}}</div>
        <el-input style="width: 180px; margin-top: 5px" size="mini" v-model="trigger.name" />
      </div>

      <div class="when-then" @click="handleWhenClick">
        <div>
          <div>{{$t('triggers.when')}}</div>
          <div style="margin-top: 5px;">{{whenDescription}}</div>
        </div>

        <i class="fa fa-chevron-right"></i>

      </div>


      <div class="when-then" @click="handleThenClick">
        <div>
          <div>{{$t('triggers.then')}}</div>
          <div style="margin-top: 5px;" v-html="thenDescription"></div>
        </div>
        <i class="fa fa-chevron-right"></i>
      </div>


    </div>


  </div>
</template>

<script>
  import Navigation from '@/components/Navigation'
  var moment = require('moment');

  export default {
    name: "NewTrigger",

    components: {
      'app-navigation': Navigation,
    },

    data: function () {
      return {
        trigger: {},
        inChildComponents: false
      }
    },

    computed: {
      whenDescription: function () {
        if (this.trigger.condition.description)
          return this.trigger.condition.description
        else
          return this.$t('triggers.condition-description')
      },
      thenDescription: function () {
        if (this.trigger.actions && this.trigger.actions.length > 0) {
          return this.trigger.actions.map(action => action.description).join(", ")
        } else {
          return this.$t('triggers.action-description')
        }
      }
    },


    methods: {
      handleWhenClick: function(){
        this.inChildComponents = true
        if (this.$route.params.id) {
          if (this.trigger.condition.category == "Schedule") {
            this.$router.push({name: 'WhenScheduleEdit', params: {edit: true}})
          } else {
            this.$router.push({name: 'WhenEdit', params: {edit: true}})
          }
        } else {
          this.$router.push({name: 'TriggerTypes'})
        }
      },

      handleThenClick: function(){
        this.inChildComponents = true
        this.$router.push({name: 'Then'})
      },

      saveTrigger(){
        let now = moment().format("DD.MM.YYYY HH:mm:ss")
        console.log("Now", now)

        //let trigger = this.$store.getters.triggerTestData

        let condition = this.$store.getters.triggerData.condition
        let actions = this.$store.getters.triggerData.actions

        if (!this.trigger.name) {
          this.$message({ type: "error", message: this.$t('triggers.form-rules.name-empty') })
          return
        } else if (!condition || !condition.id || !condition.symbol) {
          this.$message({ type: "error", message: this.$t('triggers.form-rules.add-condition') })
          return
        } else if (!actions || actions.length == 0) {
          this.$message({ type: "error", message: this.$t('triggers.form-rules.add-action') })
          return
        }

        if (this.$route.params.id) {
          this.$store.dispatch("editTrigger", {...this.trigger, lastModifiedDate: now, condition: condition, actions: actions}).then(response => {
            this.$router.push({name: "Triggers"})
          }).catch(reason => {
          })
        } else {
          this.$store.dispatch("addTrigger", {name: this.trigger.name, createDate: now, lastModifiedDate: now, condition: condition, actions: actions, executable: true }).then(response => {
            this.$router.push({name: "Triggers"})
          }).catch(reason => {
          })
        }
      }
    },

    created(){
      this.trigger = this.$store.getters.triggerData
      this.inChildComponents = false

      console.log("trigger", JSON.stringify(this.trigger, null, 2))
    },

    destroyed(){
      if (!this.inChildComponents)
        this.$store.commit("resetTriggerData")
    }

  }
</script>

<style scoped>
  .when-then {
    margin-top: 25px;
    padding: 10px;
    color: #545c64;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px dashed #eeeeee;
  }
  .when-then:hover {
    background-color: #eeeeee;
  }
</style>